<template>
	<div class="excel-source">
		<!--button type="button" class="download-btn" v-on:click="download">Download</button-->
		
		<div class="stepper-md mb-5 pb-5">
			<div class="mdl-card mdl-shadow--2dp">
				<div class="mdl-card__supporting-text">
					<div class="mdl-stepper-horizontal-alternative">
						<div v-for="(item, key) in this.tabsPanel"
							:class="['mdl-stepper-step', item.selected ? 'active-step' : '', item.finished ? 'step-done' : '' ]" :key="`tab-${key}`">
							<div class="mdl-stepper-circle excel-circle"><span>{{ item.id }}</span></div>
							<div class="mdl-stepper-title"> {{ $t(item.name) }} </div>
							<div class="mdl-stepper-bar-left"></div>
							<div class="mdl-stepper-bar-right"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="row" v-show="this.tabsPanel[0].selected && !this.tabsPanel[0].finished" :key="`A-${1}`">
				
				<div class="col-md-7 mx-auto">
					<h3>Excel</h3>
					<p>
						{{ $t('ExcelDesc') }}
					</p>
					<div>
						<div class="exel-etape-title">
							<p>{{ $t('ExcelTitle') }}</p>
							<span class="showerrors" style="display: none;color: red"></span><br>
						</div>
						<div class="exel-etap-upload">
							<div class="box text-center">
								<input type="file" name="file" @change="previewFiles" id="fileImport" accept=".xlsx,.csv" required class="inputfile fileinput inputfile-1" />
								<label for="fileImport" class="m-0">
									<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32px" height="31px">
										<image  x="0px" y="0px" width="32px" height="31px"  xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAfCAQAAAAsXwcHAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfiCQYONSO167NKAAABYUlEQVQ4y6WVvUoDURCFv2tSmEajAcHKHwRBiOwDiIJgZXwNhTxGXsTnSCv2QiKiYKGlNppGUJDPIjHJxt3lrjnVYWbnsPfcuTOBTFijSUJCwj6PISEXIVW2NipK2GVhKrES3vMEqi6wMy5bpzSq9NkrXzZ1BJ2nnP68AlSjvtpkkBl/ixUYZN+CkLqsfLyp2gOwN+EUCHxxQmsmFgz8ds6kf5yg5bEfI34OHowzG9atGwAMU1ydFjgEz/xWL8GGz+NMPfs3ZwVe3ALb3lizYtdZ/PFgVkDvXQWXwI5/0TeA/SHPFtArSJ2+lAeqHQAXvU5FIz3QrhVrXoANH8p78GwDvFTb4LavZT04AM9V/fYMPCrrwaknfo74h8e2yntQjEwP4h5TwVuIGyjDeTAIgoHlMTdWIGcqxwsUoIwHWbitchcx1vNmIgxC1GIp2Exzr7aQHU4t1yea4f9WO2yfXPwAVQ8t+eqbqosAAAAASUVORK5CYII=" />
									</svg>
									<span class="namefile">{{ fileImport != '' ? fileImport.name : $t('ImportExcelFile') }} <i class="fa fa-spinner fa-spin" v-if="loadingFile"></i></span>
								</label>
							</div>
						</div>
					</div>
					
				</div>
			</div>
			<div class="row" v-show="this.tabsPanel[1].selected && !this.tabsPanel[1].finished" :key="`A-${2}`">
				<div class="col-md-7 mx-auto">
					
					<div class="exel-etape-2 mt-4">
						<div class="exel-etape-title">
							<h3>2 - {{ $t('FieldsMapping') }}</h3>
						</div>
						
						<div class="row" >
							<div class="col-md-12">
								<div :class="['form-group row', validate ? 'has-error' : '']">
									<div class="col-md-12 my-auto">
										<label for="sourceName" class="m-0">{{ $t('NameYourSource') }}</label>
									</div>
									<div class="col-md-12">
										<input id="sourceName" type="text" class="form-control" placeholder="Lead source will have the same name" v-model="sourceName" />
										<small v-if="validate" class="help-block">{{ $t('NameSourceTitle') }}</small>
									</div>
								</div>
								
								<p>{{ $t('MatchTitle') }}</p>
								<div class="form-group row" v-for="(item, key) in fieldsFile" v-bind:key="`excel-${key}`">
									<div class="col-md-4 my-auto">
										<label :for="`field-${item.field}`" class="m-0" v-html="item.field"></label>
									</div>
									<div class="col-md-1 my-auto">
										<i class="fa fa-long-arrow-right"></i>
									</div>
									<div :class="[ item.selected == 'custom_field' ? 'col-md-3' : 'col-md-7']">
										<select :id="`field-${item.field}`" class="custom-select form-control" v-model="item.selected" @change="selectFieldMapping(item)"><!--  -->
											<option selected value="">{{ $t('OptionSelect') }}</option>
											<template v-for="(el, id) in fieldsSelect">
												<option :value="el.field" v-text="el.title" v-bind:key="`field-${id}`" :disabled="el.selected && el.field != 'custom_field'"></option>
												<!-- !el.selected || item.selected == el.field -->
											</template>
										</select>
									</div>
									<div v-show="item.selected == 'custom_field'" class="col-md-4">
										<input type="text" class="form-control" v-model="item.label" />
									</div>
								</div>
							</div>
							<div class="col-md-12">
								<b-button variant="success" class="pull-right" @click="saveFileExcel">
									<i :class="[ loading ? 'fa fa-spinner fa-spin' : 'fa fa-cogs' ]"></i> {{ $t('Finish') }}
								</b-button>
							</div>
						</div>
					</div>
					
				</div>
			</div>
			<div class="row" v-show="this.tabsPanel[2].selected && !this.tabsPanel[2].finished" :key="`A-${3}`">
				<div class="col-md-8 mx-auto">
					<div class="collapse-md">
						
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>
<script>
	import XLSX from 'xlsx';
	export default {
		name:'ImportExcelFile',
		components: {
			
		},
		data() {
			return {
				
				tabsPanel: [
					{ id:1, name:'Import File', selected: true,  finished: false },
					{ id:2, name:'Mapping Fields', selected: false, finished: false },
					{ id:3, name:'Confirm', selected: false, finished: false },
				],
				
				loadingFile	: false,
				loading		: false,
				validate	: false,
				
				fileImport	: [],
				sourceName	: '',
				fieldsFile	:[],
				dataArray	:[],
				fieldsSelect: [
					
					{ field: 'full_name',  			title: this.$t('FullName'), 	selected: false	},
					{ field: 'personal_first_name', title: this.$t('FirstName'), 	selected: false	},
					{ field: 'personal_last_name',  title: this.$t('LastName'), 	selected: false	},
					{ field: 'email',      			title: this.$t('Email'), 		selected: false	},
					{ field: 'personal_phone',     	title: this.$t('Phone'), 		selected: false	},
					{ field: 'created_at',			title: this.$t('GeneratedOn'), 	selected: false	},
					{ field: "custom_field", 		title: this.$t("CustomField"), 	selected: false },
					
				],
				// mappingArray: []
			}
		},
		methods: {
			
			stepPrevious(element) {
				
				this.tabsPanel.forEach( function( el, key ) {
					if( element < key ) {
						el.selected = false
						el.finished = false
					}
				} );
				
				this.tabsPanel[element].selected = true;
				this.tabsPanel[element].finished = false;
				
			},
			
			stepNext(element) {
				
				this.tabsPanel.forEach( function( el ) {
					
					if( element > el.id ) {
						el.selected = true
						el.finished = true
					} else if( element == el.id ) {
						el.selected = true;
						el.finished = false;
					} else {
						el.selected = false
						el.finished = false
					}
					
				} );
				
			},
			
			selectFieldMapping(element) {
				let that = this
				
				that.fieldsSelect.forEach( function(item) {
					if( element.oldValue == item.field ) {
						element.oldValue = ''
						item.selected = false
					}
				} )
				
				that.fieldsSelect.forEach( function(item) {
					if( element.selected == item.field ) {
						element.oldValue = element.selected
						item.selected = true
					}
				} )
			},
			
			notifyMe(message, variant){
				this.$notify({
					group	: 'foo',
					// title: 'Important message',
					text	: message,
					type	: variant,
					// duration: 5000,
					// speed	: 1000
				});
				
			},
			
			saveFileExcel() {
				let that = this
				
				that.validate = false
				
				let stop = true
				that.fieldsSelect.forEach( function(item) {
					if( item.selected ) {
						stop = false
					}
				} )
				
				if( stop ) {
					that.notifyMe(this.$t('MapYourFields'), 'warn')
					return false
				}
				
				if( that.sourceName != '' ) {
					
					that.loading = true
					let fileFieldInfo = []
					fileFieldInfo.push({
						name		:that.fileImport.name,
						lastModified:that.fileImport.lastModified,
						size		:that.fileImport.size,
						type		:that.fileImport.type
					})
					
					let url		= 'save_excel_source'
					let method	= 'POST'
					let data	= { fieldsFile: that.fieldsFile, dataArray: that.dataArray, sourceName: that.sourceName, fileInfo: fileFieldInfo, provider : "add sources", permissionid : 1 }
					
					that.$store.dispatch('apiRequest', { url, method, data } ).then(() => {
						// console.log(response.data)
						that.notifyMe('<div id="source-added-excel">' +this.$t('ChangesSaved') + '</div>', 'success')
						setTimeout( function() {
							that.$router.push({ name: "myLeads" })
						}, 1000)
						that.loading = false
						that.leftSidebarInfo()
					}).catch(() => {
						that.loading = false
						that.$router.push({ name: "myLeads" })
					})
					
				} else {
					that.validate = true
				}
				
			},
			
			previewFiles(e) {
				let that = this
				var files = e.target.files, f = files[0];
				
				that.fileImport 	= e.target.files[0]
				that.sourceName 	= e.target.files[0].name
				that.fieldsFile		= []
				// console.log(that.fileImport)
				that.loadingFile = true
				var reader = new FileReader();
				reader.onload = function(e) {
					var data = new Uint8Array(e.target.result);
					var workArray = XLSX.read(data, {type: 'array', cellDates:true /* , cellStyles:true */ });
					let sheetName = workArray.SheetNames[0]
					/* DO SOMETHING WITH workArray HERE */
					/* console.log(workArray); */
					let worksheet = workArray.Sheets[sheetName];
					/* console.log(XLSX.utils.sheet_to_json(worksheet)); */
					let fields = XLSX.utils.sheet_to_json(worksheet,{defval:""});
						fields = Object.keys(fields[0])
					
					fields.forEach( function(item, id) {
						
						that.fieldsFile.push({ field: item, selected: '', oldValue: '', label: item })
						
						let fieldValue = item.toLowerCase()
						that.fieldsSelect.forEach( function(col) {
							
							if( !col.selected ) {
								if( fieldValue == col.field || fieldValue.includes(col.title.toLowerCase()) ) {
									col.selected = true
									that.fieldsFile[id].selected = col.field
									that.fieldsFile[id].oldValue = col.field
								} else if ( 
									(fieldValue == 'name' && col.field == 'full_name')
									||  (fieldValue.includes('first') && fieldValue.includes('name') && col.field == 'personal_first_name' )
									||  (fieldValue.includes('last') && fieldValue.includes('name') && col.field == 'personal_last_name' )
								) {
									col.selected = true
									that.fieldsFile[id].selected = col.field
									that.fieldsFile[id].oldValue = col.field
								}
							}
							
						} )
						
						if ( that.fieldsFile[id].selected == '' ) {
							// col.selected = true
							that.fieldsFile[id].selected = 'custom_field'
							that.fieldsFile[id].oldValue = 'custom_field'
						}
						
					} );
					// that.fieldsFile
					
					that.dataArray 	= XLSX.utils.sheet_to_json(worksheet,{defval:""})
					that.stepNext(2)
					that.loadingFile = false
				};
				reader.readAsArrayBuffer(f);
			},
			leftSidebarInfo() {
				let that = this
				let url   = 'left_sidebar'
				let method= 'GET'
				let data  = {}
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					console.log(response)
				}).catch(( {response} ) => {
					console.log(response)
				})
			},
		},
		
	}
</script>
<style lang="scss">
    
	.excel-source {
		.stepper-md .mdl-stepper-horizontal-alternative .mdl-stepper-step.active-step .mdl-stepper-circle {
			background-color: #4caf50 !important;
		}
		
		.active-step {
			.mdl-stepper-title {
				color: #4caf50 !important;
			}
		}
		
		.mdl-stepper-horizontal-alternative .mdl-stepper-step.active-step.step-done .mdl-stepper-title {
			color: #4caf50 !important;
		}
		
		
	}
	
	.loading{
        background-image : url('http://www.fotos-lienzo.es/media/aw_searchautocomplete/default/loading.gif');
        background-repeat:no-repeat;
    }
    .loading:after {
        content: "Logging in...";
        text-align : right;
        padding-left : 25px;
    }
	.selectedoption{
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
</style>